var site = site || {};
var Drupal = Drupal || {};

(function ($, generic) {
  var titleTemplate;
  var titleVars = {};
  var $titles = $();
  var titleClass = 'signin-block__authed__nav__title';
  var rClass = titleClass + '--rendered';

  function _renderTitles() {
    if(titleTemplate !== undefined){
      $titles.html(Mustache.render(titleTemplate, titleVars)).addClass(rClass);
    }
  }

  function _setUserName() {
    var $signedUserName = $('.js-signed-in-user-name');
    var prefix = $signedUserName.data('user-prefix');
    var suffix = $signedUserName.data('user-suffix');

    if (titleVars.first_name !== null) {
      $signedUserName.html(prefix + ' ' + titleVars.first_name + ' ' + suffix);
    }
  }

  Drupal.behaviors.signinBlock = {
    attach: function (context) {
      var $blocks = $('.js-signin-block', context);

      $titles = $titles.add($('.' + titleClass, $blocks));
      var firstName = site.userInfoCookie.getValue('first_name');
      var pathname = window.location.pathname;

      if (firstName) {
        titleVars.first_name = firstName;
      }
      if (!titleTemplate) {
        titleTemplate = $titles.first().html();
      }
      _renderTitles();
      _setUserName();
      // This is done for forgot password
      var $forgotPassword = $('.signin-block__forgot-password', context);

      if ($forgotPassword.length > 0) {
        site.signin.forgotPassword({
          resetPassword: true,
          emailNode: $('input#drp_login_EMAIL_ADDRESS', context),
          errorListNode: ' ',
          forgotPasswordLink: $forgotPassword
        });
      }
      var $resetPasswordLink = $('.js-forgot-password', context);
      var $returnUserContext = $('.return-user', context);
      var $signinEmailAddress = $('.js-return-user-email', $returnUserContext);

      if ($resetPasswordLink && ($signinEmailAddress && $signinEmailAddress.length)) {
        site.signin.forgotPassword({
          resetPassword: true,
          emailNode: $signinEmailAddress,
          errorListNode: ' ',
          forgotPasswordLink: $resetPasswordLink
        });
      }
      $blocks.each(function () {
        var $block = $(this);
        var $toggleTrigger = $('.js-signin-block-toggle', this);
        var $ppTrigger = $('.js-pure-privilege-form-toggle', this);
        var $ppForm = $('.js-pure-privilege-form', this);
        var $registerForm = $('.signin-block__form--register', this);
        var $signinBlockForms = $('.signin-block__form', $block);
        var $returnUrls = $('input[name="RETURN_URL"]', $signinBlockForms);
        var $toggleRegisterPass = $('.js-show-password', $registerForm);
        var $registerPass = $('.signin-block__field--password', $registerForm);
        var showPass = false;

        if (Drupal.settings && Drupal.settings.reset_return_url) {
          if (pathname.search('password_request') < 0 || pathname.search('confirm') < 0 || pathname.search('signin') < 0) {
            $returnUrls.val(pathname);
          }
        }

        function togglePassword() {
          showPass = !showPass;
          $registerPass.get(0).type = showPass ? 'text' : 'password';
          $block.toggleClass('signin-block--show-password', showPass);
        }

        function toggleSmsInput() {
          var $wrapper = $('.js-sms_promo_mobile_number', $registerForm);
          var $checkbox = $('input.js-sms_options', $registerForm);

          if ($wrapper.length > 0) {
            $wrapper[$checkbox.is(':checked') ? 'show' : 'hide']();
            $checkbox.off('change.sms').on('change.sms', function () {
              if ($(this).is(':checked')) {
                $wrapper.fadeIn();
              } else {
                $wrapper.fadeOut();
              }
            });
          }
        }

        toggleSmsInput();

        $toggleRegisterPass.on('click', function (event) {
          togglePassword();
        });

        $registerForm.on('submit', function () {
          if ($.cookie('csrftoken')) {
            $('.js-pp-fieldset').append('<input type="hidden" name="_TOKEN" value="' + $.cookie('csrftoken') + '" />');
          }
          if (showPass) {
            togglePassword();
          }
        });

        $toggleTrigger.on('click', function (event) {
          event.preventDefault();
          $block.toggleClass('signin-block--signin');
          $('.js-nav-close').focus();
          site.restrict_navigation($('.js-signin-block'));
        });

        $('.js-registration-submit, .js-signin-submit').on('focusout', function (e) {
          $('.js-nav-close').focus();
        });

        $ppTrigger.val([]).on('change', function () {
          if ($ppForm.hasClass('hidden')) {
            $ppForm.removeClass('hidden');
          } else {
            $ppForm.addClass('hidden');
            // reset form to empty values
            $ppForm.find('input[type="text"], select').val([]).trigger('select.enabled');
          }
        });

        if (($ppTrigger[0] !== undefined && $ppTrigger[0].hasAttribute('checked')) || $ppForm.find('input[type="text"]').val() && $ppForm.hasClass('hidden')) {
          $ppForm.removeClass('hidden');
          $ppTrigger.attr('checked', 'checked');
        }

        $ppTrigger.find('a').click(function (e) {
          e.stopPropagation();
        });

        site.pp_number_forgot.init($ppForm);
      });

      if (generic.cookie('USER_RECOGNIZED')) {
        $blocks.addClass('signin-block--signin');
      }

      if (Drupal.settings && Drupal.settings.common && Drupal.settings.common.adv_legal) {
        $('.js-commercial').on('change', function () {
          if ($(this).is(':checked')) {
            $('.js-mobile-container').removeClass('hidden');
          } else {
            $('.js-mobile-container').addClass('hidden');
          }
        });
      }
    }
  };

  $(document).on('user.loaded', function (event, val) {
  // This is brittle but there's no other way to tell from the data we get.
    if (!$titles.length) {
      $titles = $('.' + titleClass);
    }
    if (!titleTemplate) {
      titleTemplate = $titles.first().html();
    }
    titleVars.first_name = val.first_name;

    // If user is ever signed in, permanently store a "USER_REC" cookie. This
    // only expires once a year.
    if (val.signed_in) {
      generic.cookie('USER_RECOGNIZED', 1, {
        path: '/',
        expires: 365
      });
      $('body').addClass('signed-in');
    }

    _renderTitles();
    _setUserName();
  });
})(jQuery, window.generic || {});
